import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendData, validateEmail, validatePhoneNumber } from '../../helpers';

const BasicForm = ({ model, label }) => {
  const { representingEntityList, areasOfInterestList } = model.comboValues;
  const { title, description } = model;
  const {
    representingLbl,
    firstNameLbl,
    lastNameLbl,
    emailLbl,
    phoneLbl,
    areasOfInterestLbl,
    textAreaLbl,
    submitButtonLbl,
    culture,
  } = label;

  const [errors, setErrors] = useState({});
  const [reCaptchaValue, setReCaptchaValue] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  function onChangeReCaptcha(value) {
    setReCaptchaValue(value);
  }

  const [formValues, setFormValues] = useState({
    representing: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    areasOfInterest: '',
    text: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    if (e.target.tagName === 'SELECT') {
      if (value) {
        e.target.classList.add('has-value');
      } else {
        e.target.classList.remove('has-value');
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.representing) newErrors.representing = 'Field is required';
    if (!formValues.firstName) newErrors.firstName = 'Field is required';
    if (!formValues.lastName) newErrors.lastName = 'Field is required';

    if (!formValues.email) {
      newErrors.email = 'Field is required';
    } else if (!validateEmail(formValues.email)) {
      newErrors.email = 'Invalid email';
    }

    if (!formValues.phone) {
      newErrors.phone = 'Field is required';
    } else if (!validatePhoneNumber(formValues.phone)) {
      newErrors.phone = 'Invalid phone';
    }

    if (!formValues.areasOfInterest)
      newErrors.areasOfInterest = 'Field is required';
    if (!formValues.text) newErrors.text = 'Field is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!reCaptchaValue) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reCaptcha: 'Recaptcha invalid',
      }));
      return;
    }

    if (!validateForm()) {
      console.log('El formulario tiene errores');
      return;
    }

    try {
      const { success, message } = await sendData(formValues);
      setFeedbackMessage(message);
      if (success) {
        setFormValues({
          representing: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          areasOfInterest: '',
          text: '',
        });
        setReCaptchaValue(false);
      }
    } catch (error) {
      console.error('Error el formulario:', error);
    }
  };

  return (
    <div className="grid module basic-form slow-y">
      <div className="grid-container--left">
        <div className="content-container">
          {title && <h3 className="title--l slow--y">{title}</h3>}
          {description && (
            <div
              className="body--m slow--y"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      </div>
      <div className="grid-container--right">
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label
              className="taglabel taglabel-slct required"
              htmlFor="representing"
            >
              {representingLbl}
            </label>
            <div class="custom-select-wrapper">
              <select
                id="representing"
                name="representing"
                value={formValues.representing}
                onChange={handleChange}
                className="custom-select"
              >
                <option value="" disabled></option>
                {representingEntityList.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            {errors.representing && (
              <span className="error">{errors.representing}</span>
            )}
          </div>
          <div className="form-group form-row">
            <div className="form-row__item">
              <input
                type="text"
                name="firstName"
                value={formValues.firstName}
                onChange={handleChange}
                className={`body--m ${formValues.firstName ? 'not-empty' : ''}`}
              />
              <label className="taglabel required" htmlFor="firstName">
                {firstNameLbl}
              </label>
              {errors.firstName && (
                <span className="error">{errors.firstName}</span>
              )}
            </div>
            <div className="form-row__item form-row__item__mobile">
              <input
                type="text"
                name="lastName"
                value={formValues.lastName}
                className={`body--m ${formValues.lastName ? 'not-empty' : ''}`}
                onChange={handleChange}
              />
              <label className="taglabel required" htmlFor="lastName">
                {lastNameLbl}
              </label>
              {errors.lastName && (
                <span className="error">{errors.lastName}</span>
              )}
            </div>
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              value={formValues.email}
              className={`body--m ${formValues.email ? 'not-empty' : ''}`}
              onChange={handleChange}
            />
            <label className="taglabel required" htmlFor="email">
              {emailLbl}
            </label>
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="form-group">
            <input
              type="tel"
              name="phone"
              value={formValues.phone}
              className={`body--m ${formValues.phone ? 'not-empty' : ''}`}
              onChange={handleChange}
            />
            <label className="taglabel required" htmlFor="phone">
              {phoneLbl}
            </label>
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>
          <div className="form-group">
            <label
              className="taglabel taglabel-slct required"
              htmlFor="areasOfInterest"
            >
              {areasOfInterestLbl}
            </label>
            <div class="custom-select-wrapper">
              <select
                name="areasOfInterest"
                value={formValues.areasOfInterest}
                onChange={handleChange}
                className="custom-select"
              >
                <option value="" disabled></option>
                {areasOfInterestList.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            {errors.areasOfInterest && (
              <span className="error">{errors.areasOfInterest}</span>
            )}
          </div>
          <div className="form-group">
            <label className="taglabel" htmlFor="turbineMessage">
              {textAreaLbl}
            </label>
            <textarea
              id="txtMsg"
              name="text"
              value={formValues.text}
              onChange={handleChange}
              className={`body--m ${formValues.text ? 'not-empty' : ''}`}
            />
            {errors.text && <span className="error">{errors.text}</span>}
          </div>
          <div className="button-container">
            <ReCAPTCHA
              className="re-captcha"
              sitekey="6LeHDZwkAAAAAOJ7KFBe-x-ntDwXVCvWvemX4lbp"
              onChange={onChangeReCaptcha}
              hl={culture === 'br' ? 'pt-br' : culture}
            />
            {errors.reCaptcha && (
              <span className="error">{errors.reCaptcha}</span>
            )}
            <button
              className="button taglabel button--lg type__red form_submit"
              type="submit"
            >
              {submitButtonLbl}
            </button>
          </div>

          {feedbackMessage && (
            <p className="feedback-message body--m">{feedbackMessage}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default BasicForm;
