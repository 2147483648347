const rndKeyGen = () =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.(com|org|net|edu|gov|io|info|co|[a-z]{2})$/i;
  return re.test(String(email).toLowerCase());
};

const validatePhoneNumber = (phone) => {
  const re = /^(?:\+?\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
  return re.test(phone);
};

const validateCIF = (cif) => {
  const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
  var match = cif.match(CIF_REGEX);
  if (!match) return false;
  var letter = match[1],
    number = match[2],
    control = match[3];

  var even_sum = 0;
  var odd_sum = 0;
  var n;

  for (var i = 0; i < number.length; i++) {
    n = parseInt(number[i], 10);

    // Odd positions (Even index equals to odd position. i=0 equals first position)
    if (i % 2 === 0) {
      // Odd positions are multiplied first.
      n *= 2;

      // If the multiplication is bigger than 10 we need to adjust
      odd_sum += n < 10 ? n : n - 9;

      // Even positions
      // Just sum them
    } else {
      even_sum += n;
    }
  }

  var control_digit = 10 - (even_sum + odd_sum).toString().substr(-1);
  var control_letter = 'JABCDEFGHI'.substr(control_digit, 1);

  // Control must be a digit
  if (letter.match(/[ABEH]/)) {
    return control == control_digit;

    // Control must be a letter
  } else if (letter.match(/[KPQS]/)) {
    return control == control_letter;

    // Can be either
  } else {
    return control == control_digit || control == control_letter;
  }
};

function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

function findGetParameter(parameterName) {
  var result = '',
    tmp = [];
  location.search
    .substr(1)
    .split('&')
    .forEach(function(item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

function setCookie(name, value, minutes) {
  var expires = '';
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = 'expires=' + date.toUTCString();
  }
  document.cookie = [
    name +
      '=' +
      encodeURIComponent(JSON.stringify(value)) +
      ';' +
      expires +
      ';path=/',
  ].join('');
}
function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0)
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
  }
  return null;
}

export {
  rndKeyGen,
  validateEmail,
  validateCIF,
  bytesToSize,
  replaceAll,
  findGetParameter,
  setCookie,
  getCookie,
  validatePhoneNumber,
};

export const getMonthsArray = (culture) => {
  let monthsArray = [];
  /*    alert(culture);*/
  switch (culture) {
    case 'es':
      monthsArray = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ];
      break;
    case 'no':
      monthsArray = [
        'Januar',
        'Februar',
        'Mars',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Desember',
      ];
      break;
    case 'pt':
    case 'br':
      monthsArray = [
        'Janeiro',
        'Fevereiro',
        'Mar�o',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];
      break;
    default:
      monthsArray = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      break;
  }

  return monthsArray;
};

export const getSolutionsArray = (culture) => {
  let solutionsArray = [];
  switch (culture) {
    case 'es':
      solutionsArray = [
        { name: 'Energía', value: 'Energia' },
        { name: 'Transporte', value: 'Transporte' },
        { name: 'Agua', value: 'Agua' },
        { name: 'Sociales', value: 'Social' },
        { name: 'Ciudades', value: 'Ciudades' },
        //{ name: 'Financieras', value: 'Capital' },
        // { name: 'Inmobiliaria', value: 'Inmobiliaria' },
      ];
      break;
    case 'no':
      solutionsArray = [
        { name: 'Energi', value: 'Energia' },
        { name: 'Samferdsel', value: 'Transporte' },
        { name: 'Vann', value: 'Agua' },
        { name: 'Sosial', value: 'Social' },
        { name: 'Byer', value: 'Ciudades' },
        //{ name: 'Finansiell', value: 'Financial' },
        // { name: 'Eiendom', value: 'Inmobiliaria' },
      ];
      break;
    default:
      solutionsArray = [
        { name: 'Energy', value: 'Energia' },
        { name: 'Transport', value: 'Transporte' },
        { name: 'Water', value: 'Agua' },
        { name: 'Social', value: 'Social' },
        { name: 'Cities', value: 'Ciudades' },
        //{ name: 'Financial', value: 'Financial' },
        // { name: 'Real Estate', value: 'Inmobiliaria' },
      ];
      break;
  }

  return solutionsArray;
};

export function getSiteLanguage() {
  let siteLanguage;

  if (document.documentElement.lang.startsWith('es')) {
    siteLanguage = 'español';
  } else if (document.documentElement.lang.startsWith('en')) {
    siteLanguage = 'inglés';
  } else if (document.documentElement.lang.startsWith('no')) {
    siteLanguage = 'noruego';
  } else if (
    document.documentElement.lang.startsWith('pt') ||
    document.documentElement.lang.startsWith('br')
  ) {
    siteLanguage = 'portugués';
  } else {
    siteLanguage = 'otro';
  }

  return siteLanguage;
}

export function removeAccents(str) {
  if (str != null) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
      .replace(' ', '-')
      .toLowerCase();
  }
}

export const sendData = async (formData) => {
  try {
    const response = await fetch('/SendBasicFormMail/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      return { success: true, message: 'Form successfully submitted.' };
    } else {
      return { success: false, message: 'Error sending the form.' };
    }
  } catch (error) {
    return {
      success: false,
      message: 'Failed to connect to the server.',
    };
  }
};
